.prf-popup-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.prf-popup {
  position: relative;
  width: 412px;
  min-height: 300px;
  padding: 52px;
  background-color: #fff;
  box-shadow: 2px 2px 15px rgba(53, 53, 53, 0.08);
  border-radius: 16px;
}

.prf-popup-title {
  margin: 0 0 24px;
  font-family: Avenirnextcyr, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #353535;
}

.prf-popup-content {
  margin: 0 0 48px;
}

.prf-popup-text {
  font-family: Avenirnextcyr, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #353535;
}

.prf-popup-buttons {
  display: flex;
  margin-top: 48px;

  &_column {
    flex-direction: column;
  }
}

.prf-popup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 12px 0;
  width: 100%;
  font-family: Avenirnextcyr, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 30px;
  cursor: pointer;

  &.prf-popup-button_primary {
    color: #fff;
    background-color: #3d5adf;
    box-shadow: 0 5px 20px rgba(61, 90, 223, 0.3);

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #3d5adf;
    }

    &:active {
      background-color: #8b9cec;
    }
  }

  &.prf-popup-button_secondary {
    color: #353535;
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(53, 53, 53, 0.15);
  }

  &.prf-popup-button_text {
    color: #3d5adf;

    &:active {
      color: #8b9cec;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    color: #d3d3d3;
    box-shadow: none;
  }
}

.show-messages {
  &-main-text {
    font-weight: 700;
  }

  &-title-text {
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #353535;
    margin-bottom: 0;
  }

  &__message {
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #353535;
  }

  &__message-small {
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #353535;
    margin-bottom: 0;
  }

  &-left-position-text {
    margin: 0 6px 0 0;
  }

  &-right-position-text {
    color: #ffffff;
    margin-bottom: 0;
  }

  &__icon {
    width: 8px;
    height: 8px;
    background-color: rgba(106, 82, 255, 1);
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    margin: 8px 16px 0 0;

    &-arrow {
      width: 20px;
      height: 20px;
      background-image: url('/assets/images/icons/arrow-message-list.svg');
      margin: 2px 8px 0 0;
    }
  }

  &__list {
    display: flex;
    margin-top: 8px;
  }
}

@forward 'common-styles/buttons';
@forward 'common-styles/common';
@forward 'common-styles/container';
@forward 'common-styles/helpers';
@forward 'common-styles/inputs';
@forward 'common-styles/popup';
@forward 'common-styles/onboarding';
@forward 'custom-test-style/bvb-test.component';

* {
  box-sizing: border-box;
}

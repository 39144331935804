.pupil-wrapper_mobile {
  margin: 10px auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  height: 100vh;
  overflow: hidden;
}

.pupil-container_mobile {
  height: calc(var(--prf-app-height) - 135px);
  overflow-y: auto;
  padding-bottom: 28px;
}

// Change scale
$mod_1: 1.067;
$mod_2: 1.3;

// Calculate Modular Scale
// You don't need to calculate this with Sass
// You can use values from http://type-scale.com/
// Or random values :)

$h1-min: $mod_1 * $mod_1 * $mod_1 * $mod_1 * 1rem;
$h1-max: $mod_2 * $mod_2 * $mod_2 * $mod_2 * 1rem;

$h2-min: $mod_1 * $mod_1 * $mod_1 * 1rem;
$h2-max: $mod_2 * $mod_2 * $mod_2 * 1rem;

$h3-min: $mod_1 * $mod_1 * 1rem;
$h3-max: $mod_2 * $mod_2 * 1rem;

$h4-min: $mod_1 * 1rem;
$h4-max: $mod_2 * 1rem;

$h5-min: 1rem;
$h5-max: 1rem;

$h6-min: (1rem / $mod_1);
$h6-max: (1rem / $mod_2);

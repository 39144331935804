.prf-form-control {
  position: relative;

  &_changed {
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba(53, 53, 53, 0.6);
    padding: 4px 0 0 16px;
  }

  &-img {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url('/profilum-assets/images/menu/edit-icon-black.svg');
  }

  &_not-require {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4px;
    position: absolute;
    height: 20px;
    right: 12px;
    top: -10px;
    background-color: #fff;
    font-family: Avenirnextcyr, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(53, 53, 53, 0.6);
  }

  &.incorrect-field {
    border: 2px solid #f05f4c;
  }
}

.prf-form-label {
  position: absolute;
  font-family: Avenirnextcyr, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4px;
  height: 20px;
  left: 12px;
  top: -10px;
  background-color: #fff;
  color: rgba(53, 53, 53, 0.8);
}

.prf-form-input {
  height: 60px;
  width: 100%;
  border: 2px solid #f2f2f2;
  border-radius: 12px;
  padding: 20px 16px;
  outline: none;

  &.error-field {
    border: 2px solid #ff5722;
  }

  &:active {
    border: 2px solid #f2f2f2;
    box-shadow: 0 0 0 0 #f2f2f2;
  }

  &:focus-visible {
    border: 2px solid #3d5adf;
  }
}

.prf-form-password-validators {
  padding: 0 0 0 16px;
  gap: 4px;
  margin-top: 12px;

  &-message {
    display: flex;
    align-items: center;
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #353535;

    &::before {
      content: '';
      height: 12px;
      width: 12px;
      margin-right: 8px;
      background: url('/profilum-assets/images/icons/checkIcon-gray.svg') no-repeat left;
    }

    &_checked {
      &::before {
        content: '';
        height: 12px;
        width: 12px;
        margin-right: 8px;
        background: url('/profilum-assets/images/icons/checkIcon-blue.svg') no-repeat left;
      }
    }
  }
}

@use 'colors' as colors;
@use 'functions' as functions;

@mixin font-avenir-next {
  font-family: Avenirnextcyr, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
}

@mixin base-input {
  background: #fff;
  border: 2px solid colors.$color-purple;
  box-sizing: border-box;
  border-radius: 5px;
}

@mixin fluid-type($properties, $min-screen, $max-screen, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: functions.calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

//mobile
$break-mobile-max: 47.938em;

//tablet
$break-tablet-min: 48em;
$break-tablet-max: 63.938em;

//desktop
$break-desktop-min: 64em;

//mobile
@mixin break-mobile() {
  @media screen and (max-width: $break-mobile-max) {
    @content;
  }
}

//tablet
@mixin break-tablet() {
  @media screen and (min-width: $break-tablet-min) and (max-width: $break-tablet-max) {
    @content;
  }
}

//desktop
@mixin break-desktop() {
  @media screen and (min-width: $break-desktop-min) {
    @content;
  }
}

//mobile +
@mixin break-mobile-gte() {
  @content;
}

//tablet -
@mixin break-tablet-lte() {
  @media screen and (max-width: $break-tablet-max) {
    @content;
  }
}

//tablet +
@mixin break-tablet-gte() {
  @media screen and (min-width: $break-tablet-min) {
    @content;
  }
}

//desktop -
@mixin break-desktop-lte() {
  @content;
}

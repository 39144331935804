$color-white: #fff;
$color-light-purple: #9375fb;
$color-purple: #7852fb;
$color-lightpurple: #8d60ec;
$color-black: #000;
$color-black1: #333;
$color-black2: #353535;
$color-orange: #ff5722;
$color-grassgreen: #36ca75;
$color-pastel: #13e5db;
$color-pink: #e74daa;
$color-blue: #42a2ff;
$color-gray: #868686;

$gradient: linear-gradient(0.5turn, rgba(252, 251, 248, 1), rgba(252, 251, 248, 0));

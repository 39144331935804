.about-test-section_bvb {
  margin-bottom: 50px;

  .desc_bvb {
    font-family: 'Avenir', Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(53, 53, 53, 0.6);
  }
}

.container.container_bvb-test {
  width: 1050px;
  padding: 86px 195px 80px;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
}

.bvb-logo-link {
  cursor: pointer;

  .bvb-logo {
    width: 187px;
  }
}

.button-wrapper_bvb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  .button_bvb {
    padding: 19px 51px;
    background: linear-gradient(90deg, #0f9b91 0%, #00d07c 100%);
    border-radius: 500px;
    font-family: Intro, Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
  }
}

.test-info {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;

  .test-duration {
    text-align: center;

    .duration-value {
      width: 124px;
      height: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(15, 155, 145, 0.05);
      font-family: IntroBold, Arial, sans-serif;
      font-weight: 700;
      font-size: 56px;
      line-height: 140%;
      text-align: center;
      color: #0f9b91;
    }

    .duration-units {
      font-family: 'IntroBold', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      margin-top: 16px;
      text-align: center;
      color: #353535;
      opacity: 0.6;
    }
  }

  .questions-quantity {
    text-align: center;

    .quantity-value {
      width: 124px;
      height: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(15, 155, 145, 0.05);
      font-family: IntroBold, Arial, sans-serif;
      font-weight: 700;
      font-size: 56px;
      line-height: 140%;
      text-align: center;
      color: #0f9b91;
    }

    .quantity-text {
      font-family: 'IntroBold', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      margin-top: 16px;
      text-align: center;
      color: #353535;
      opacity: 0.6;
    }
  }
}
